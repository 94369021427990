
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MenuItem } from 'src/app/models/menuItem';


@Injectable({
    providedIn: 'root'
})
export class MenuService {
    path = environment.apiUrl;
    languageCode: string = '';

    constructor(private http: HttpClient) {
        this.languageCode = localStorage.getItem('language');
    }


    getAllFrontMenuItem(): Observable<MenuItem> {
        const url = this.path + `GetAllMenuItems?LanguageCode=${this.languageCode}`;
        return this.http.get<MenuItem>(url);
    }

    getFrontMenuById(): Observable<MenuItem> {
        const url = this.path + `GetMenuItemById?Id=${this.languageCode}`;
        return this.http.get<MenuItem>(url);

    }
}
