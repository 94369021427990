
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Publication } from 'src/app/models/publication';
import { environment } from 'src/environments/environment';



@Injectable({
    providedIn: 'root'
})
export class PublicationService {
    path = environment.apiUrl;

    constructor(private http: HttpClient) { }

    getAllPublicationType(): Observable<Publication> {
        const url = this.path + `GetAllPublicationType`;
        return this.http.get<Publication>(url);

    }

    getAllPublicationResource(publicationType, searchQuery, pageSize, skip): Observable<Publication[]> {
        const url = this.path + `GetAllPublication?PublicationTypeId=${publicationType}&SearchQuery=${searchQuery}&PageSize=${pageSize}&Skip=${skip}`;
        return this.http.get<Publication[]>(url);

    }

    getAllPublication(): Observable<Publication[]> {
        const url = this.path + `GetAllPublication?MainPage=true`;
        return this.http.get<Publication[]>(url);

    }

    getPublicationById(id): Observable<Publication> {
        const url = this.path + `GetPublicationById?Id=${id}`;
        return this.http.get<Publication>(url);
    }

}