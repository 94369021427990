import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve
} from '@angular/router';
import { Observable } from 'rxjs';
import { PublicationService } from '../../../services/publication.service';

@Injectable()
export class PublishResolver implements Resolve<any> {
    constructor(private publicationService: PublicationService) { }
    resolve(
        route: ActivatedRouteSnapshot
    ): Observable<any> {
        const id = route.paramMap.get('id');
        return this.publicationService.getPublicationById(id) as Observable<any>;
    }
}