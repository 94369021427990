import { Component, OnInit } from '@angular/core';
import { AppSettingService } from '../../../services/appsetting.service';
@Component({
    selector: 'app-contact-page',
    templateUrl: './contact-page.component.html',
    styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {
    appSettings: any;
    address: any;
    telephone: any;
    telephone2: any;
    fax: any;
    facebook: any;
    twitter: any;
    instagram: any;
    linkedin: any;
    youtube: any;
    funeralFundMail: any;
    email: any;
    constructor(private appSettingService: AppSettingService) { }

    ngOnInit(): void {
        this.getAppSettings()
    }

    submit(form) {
        var name = form.name;


        var email = form.email;


        var number = form.number;


        var subject = form.subject;


        var message = form.message;

    }

    getAppSettings() {
        this.appSettingService.getAppSetting().subscribe((data: any) => {
            this.appSettings = data;
            this.address = data.find(item => item.key === "adres")?.value;
            this.telephone = data.find(item => item.key === "telefon")?.value;
            this.telephone2 = data.find(item => item.key === "cenazetelefon")?.value;
            this.fax = data.find(item => item.key === "fax")?.value;
            this.funeralFundMail = data.find(item => item.key === "cenazemail")?.value;
            this.email = data.find(item => item.key === "email")?.value;
            //   this.facebook = data.find(item => item.key === "facebook")?.value;
            //   this.twitter = data.find(item => item.key === "twitter")?.value;
            //   this.instagram = data.find(item => item.key === "instagram")?.value;
            //   this.linkedin = data.find(item => item.key === "linkedin")?.value;
            //   this.youtube = data.find(item => item.key === "youtube")?.value;


        })
    }

}