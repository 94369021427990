import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve
} from '@angular/router';
import { Observable } from 'rxjs';
import { EventService } from '../../../services/event.service';

@Injectable()
export class EventsResolver implements Resolve<any> {
    constructor(private eventService: EventService) { }
    resolve(
        route: ActivatedRouteSnapshot
    ): Observable<any> {
        const code = route.paramMap.get('code');
        return this.eventService.getEventById(code) as Observable<any>;
    }
}