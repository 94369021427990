<app-banner [data]="{content: 'CONTACT' | translate, title: 'CONTACT' | translate}"></app-banner>

<div class="contact-info-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <!-- <h1 style="text-align: center;"> DİTİB - FRANSA </h1> -->
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-envelope'></i>
                    </div>
                    <h3>Email</h3>
                    <p><a href="mailto:{{email}}">{{email}}</a></p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-map'></i>
                    </div>
                    <h3>{{'ADDRESS'|translate}}</h3>
                    <p><a rel="noopener" href="https://www.google.com/maps/place/{{ address }}"
                            target="_blank">{{address}}</a></p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-phone-call'></i>
                    </div>
                    <h3>{{'PHONE'|translate}}</h3>
                    <p><a href="tel:{{ telephone }}">{{ telephone }}</a></p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-envelope'></i>
                    </div>
                    <h3>{{'FUNERAL_FUND_EMAIL'|translate}}</h3>
                    <p *ngIf="funeralFundMail"><a href="mailto:{{funeralFundMail}}">{{funeralFundMail}}</a></p>
                    <p><a href="tel:{{ telephone2 }}">{{ telephone2 }} </a></p>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="map">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2624.4405360508763!2d2.4244495999999995!3d48.8688779!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66d6e1e3ff90b%3A0xdaa2fdec2bf89777!2s58%20Rue%20L%C3%A9nine%2C%2093170%20Bagnolet%2C%20Fransa!5e0!3m2!1str!2str!4v1711358884476!5m2!1str!2str" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
</div>

