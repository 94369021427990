
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Form, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GenerateForm, GetForm, SendForm } from '../models/form';



@Injectable({
    providedIn: 'root'
})
export class FormService {
    path = environment.apiUrl;
    languageCode: string = '';

    constructor(private http: HttpClient) { }

    getFormById(id): Observable<GetForm> {
        const url = this.path + `GetFormById?Id=${id}`;
        return this.http.get<GetForm>(url);
    }

    addForm(form:SendForm): Observable<SendForm> {
        const url = this.path + `AddFormAnswer`;
        return this.http.post<SendForm>(url, form)
    }
}