
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';



@Injectable({
    providedIn: 'root'
})
export class EventService {
    path = environment.apiUrl;
    languageCode: string = '';

    constructor(private http: HttpClient) {
        this.languageCode = localStorage.getItem('language');
    }

    getAllEventsResource(searchQuery?, startDate?, endDate?, skip?, pageSize?): Observable<Event[]> {
        const url = this.path + `GetAlleventsResource?LanguageCode=${this.languageCode}&SearchQuery=${searchQuery}&StartDateTime=${startDate}&EndDateTime=${endDate}&Skip=${skip}&PageSize=${pageSize}`;
        return this.http.get<Event[]>(url);
    }

    getAllEvents(): Observable<Event[]> {
        const url = this.path + `GetAlleventsResource?LanguageCode=${this.languageCode}`;
        return this.http.get<Event[]>(url);
    }

    getEventById(code): Observable<Event> {
        const url = this.path + `GetEventById?Id=${code}`;
        return this.http.get<Event>(url);
    }
}