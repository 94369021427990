
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Education } from 'src/app/models/education';
import { environment } from 'src/environments/environment';



@Injectable({
    providedIn: 'root'
})
export class EducationService {
    path = environment.apiUrl;
    languageCode: string = '';

    constructor(private http: HttpClient) {
        this.languageCode = localStorage.getItem('language');
    }

    getAllEducationsResource(search, startDate?, endDate?, pageSize?, skip?): Observable<Education[]> {
        const url = this.path + `GetAllEducationsResource?LanguageCode=${this.languageCode}&SearchQuery=${search}&StartDate=${startDate}&EndDate=${endDate}&PageSize=${pageSize}&Skip=${skip}`;
        return this.http.get<Education[]>(url);
    }

    getAllEducations(): Observable<Education[]> {
        const url = this.path + `GetAllEducationsResource?LanguageCode=${this.languageCode}`;
        return this.http.get<Education[]>(url);
    }

    getEducationById(id): Observable<Education> {
        const url = this.path + `GetEducation?Id=${id}`;
        return this.http.get<Education>(url);
    }


}
