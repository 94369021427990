
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Footer } from 'src/app/models/footer';
import { environment } from 'src/environments/environment';



@Injectable({
    providedIn: 'root'
})
export class FooterService {
    path = environment.apiUrl;

    constructor(private http: HttpClient) {}

    getFooter(): Observable<Footer[]> {
        const url = this.path + `GetAllFooterItem`;
        return this.http.get<Footer[]>(url);
    }

}