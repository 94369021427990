import { ChildAnswers } from './../../../models/form';
import { Component, Input, OnInit } from '@angular/core';
import { Form, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { FormElement, FormElementOption, GenerateForm, GetForm, SendForm } from 'src/app/models/form';
import { FormService } from 'src/app/services/form.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-form-generate',
  templateUrl: './form-generate.component.html',
  styleUrls: ['./form-generate.component.scss']
})
export class FormGenerateComponent {
  @Input()
  forms: any;
  imgUrl: string = environment.serverUrl;
  checkboxValue: FormElementOption[] = [];
  dynamicForm: FormArray

  constructor(private formService: FormService, private fb: FormBuilder) {
    this.dynamicForm = this.fb.array([]);
  }

  ngOnInit() {
    this.getForms();
  }

  async getForms() {
    try {
      const formPromises = this.forms.map(element =>
        this.formService.getFormById(element?.formId).toPromise()
      );

      const forms = await Promise.all(formPromises);
      const formArray: GenerateForm[] = forms.map((data: GetForm) => data?.data);

      this.createForm(formArray);
    } catch (error) {
      console.error("Error fetching forms", error);
    }
  }

  createForm(formArray) {
    formArray.forEach((form: GenerateForm) => {
      const formGroup = this.fb.group({
        formId: form.id,
        childAnswers: this.fb.array([]),
        title: form.title,
        thumbnail: form.thumbnail
      });

      form.formElements.forEach((element: FormElement) => {
        const validators = [];
        let value = ''

        if (element.isRequired) validators.push(Validators.required);
        if (element.type === 'email') validators.push(Validators.email);
        if (element.type === 'radio' || element.type === 'checkbox') {
          element.formElementOptions.forEach(option => {
            if (option.selected) {
              value = option.value || '';
            }
            if (element.type === 'checkbox') {
              this.checkboxValue.push({
                formId: form.id,
                formElementId: element.id,
                value: option.value,
                checked: option.selected
              })
            }
          });
        }

        const childFormGroup = this.fb.group({
          formElementId: element.id,
          value: [value, validators],
          type: element.type,
          placeholder: element.placeholder,
          formElementOptions: this.fb.array(element.formElementOptions),
          text: element.text,
          isRequired: element.isRequired

        });

        (formGroup.get('childAnswers') as FormArray).push(childFormGroup);
      });

      this.dynamicForm.push(formGroup);
    });

    console.log('dynamicForm', this.dynamicForm.value);
  }



  changeValue(e: MatCheckboxChange, formElementId: number, formId: number) {
    console.log(e)
    this.dynamicForm.controls.forEach((dynamicFormGroup: FormGroup) => {
      if (dynamicFormGroup.get('formId').value === formId) {
        const childAnswers = dynamicFormGroup.get('childAnswers') as FormArray;

        childAnswers.controls.forEach((childFormGroup: FormGroup) => {
          if (childFormGroup.get('formElementId').value === formElementId) {
            if (childFormGroup.get('type').value === 'checkbox') {
              this.checkboxValue.map(checkValue => {
                if (checkValue.value === e.source.value) {
                  checkValue.checked = e.checked;
                }
              })
            }
            if (childFormGroup.get('type').value === 'radio') {
              childFormGroup.get('value').setValue(e.source.value);
            }
          }
        });
      }
    });
  }


  saveForm(submitForm: FormGroup) {
    if (submitForm.valid) {
      let sendForm: SendForm = {
        formId: submitForm.value.formId,
        childAnswers: []
      };

      console.log(submitForm.value)
      submitForm.value.childAnswers.map((data: FormElement) => {
        if (data.type !== 'checkbox' && data.value) {
          sendForm.childAnswers.push({
            formElementId: data.formElementId,
            value: data.value
          })
        }
        if(data.type==='checkbox') {
          this.checkboxValue.map((checkedValue => {
            console.log(checkedValue)
            if (checkedValue.checked) {
              sendForm.childAnswers.push({
                formElementId: checkedValue.formElementId,
                value: checkedValue.value
              })
            }
          }))

        }

      })
      console.log('giden data', sendForm)
      this.formService.addForm(sendForm).subscribe(data => {
        console.log('gelen data', data);
      });


    }


  }

}
