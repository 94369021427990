
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Content } from 'src/app/models/contentPage';
import { Statistic } from '../models/statistic';



@Injectable({
    providedIn: 'root'
})
export class StatisticService {
    path = environment.apiUrl;

    constructor(private http: HttpClient) {}

    getAllInfo(): Observable<Statistic[]> {
        const url = this.path + `GetAllInfo`;
        return this.http.get<Statistic[]>(url);
    }

}