import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettingService } from 'src/app/services/appsetting.service';

@Component({
  selector: 'app-left-box',
  templateUrl: './left-box.component.html',
  styleUrls: ['./left-box.component.scss']
})
export class LeftBoxComponent implements OnInit {
  active: any = false
  appSettings: any;

  yazi: any;
  baslik: any;
  link1: any;
  link2: any;
  link3: any;
  link4: any;
  link5: any;
  link6: any;
  constructor(private appSettingService: AppSettingService, private router: Router) {

  }

  ngOnInit(): void {
    this.getAppSettings()
  }

  navigate() {
    this.router.navigate(['/genel-bagis/8']).then(() => {
      window.location.reload();
    });

  }
  
  userPanel() {
    this.active = !this.active
  }

  getAppSettings() {
    this.appSettingService.getAppSetting().subscribe((data: any) => {
      this.appSettings = data;
      this.baslik = data.find(item => item.key === "panel_baslik")?.value;
      this.yazi = data.find(item => item.key === "panelyazi")?.value;
      this.link1 = data.find(item => item.key === "genelbagis")?.value;
      this.link2 = data.find(item => item.key === "sukuyusu")?.value;
      this.link3 = data.find(item => item.key === "vekaletenkurbanbagisi")?.value;
      this.link4 = data.find(item => item.key === "zekat")?.value;
      this.link5 = data.find(item => item.key === "fitre")?.value;
      this.link6 = data.find(item => item.key === "fidye")?.value;
    })
  }
}
