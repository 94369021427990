
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SmallSlider } from 'src/app/models/smallSlider';
import { SmallAnnouncement } from 'src/app/models/smallannouncement';
import { environment } from 'src/environments/environment';



@Injectable({
    providedIn: 'root'
})
export class SmallItemService {
    path = environment.apiUrl;
    languageCode: string = '';

    constructor(private http: HttpClient) {
        this.languageCode = localStorage.getItem('language');
    }

    // ~~~~~~~~~~~~~~~~~~Homepage Small Announcement~~~~~~~~~~~~~~~~~~~~~ //

    getAllSmallAnnouncement(): Observable<SmallAnnouncement[]> {
        const url = this.path + `GetAllSmallAnnouncement?languageCode=${this.languageCode}&isFront=true`;
        return this.http.get<SmallAnnouncement[]>(url);
    }

    // ~~~~~~~~~~~~~~~~~~Homepage Small Slider~~~~~~~~~~~~~~~~~~~~~ //

    getAllSmallSlider(): Observable<SmallSlider[]> {
        const url = this.path + `GetAllSmallSlider?languageCode=${this.languageCode}`;
        return this.http.get<SmallSlider[]>(url);
    }
}