import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { FooterComponent } from "./footer/footer.component";
import { HeaderStyleSixComponent } from "./header-style-six/header-style-six.component";
import { LayoutComponent } from "./layout/layout.component";
import { HttpClientModule } from "@angular/common/http";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LightgalleryModule } from "lightgallery/angular";
import { CountUpModule } from "ngx-countup";
import { LightboxModule } from "ngx-lightbox";
import { CarouselModule } from "ngx-owl-carousel-o";
import { NgxScrollTopModule } from "ngx-scrolltop";
import { AppRoutingModule } from "../app-routing.module";
import { HeaderStyleTwoComponent } from "./header-style-two/header-style-two.component";
import { LeftBoxComponent } from './left-box/left-box.component';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from "@ngx-translate/core";




@NgModule({
    declarations: [
        LayoutComponent,
        FooterComponent,
        HeaderStyleSixComponent,
        HeaderStyleTwoComponent,
        LeftBoxComponent,


    ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        CarouselModule,
        CountUpModule,
        NgxScrollTopModule,
        LightboxModule,
        FormsModule,
        LightgalleryModule,
        HttpClientModule,
        ReactiveFormsModule,
        MatSelectModule,
        TranslateModule
    ],
    exports: [
        LayoutComponent
    ],
    providers: []
})
export class CoreModule { }
