<app-banner [data]="{content: 'EVENTS' | translate, title: event.title , link:'/etkinlikler'}"></app-banner>

<div class="blog-details-area pt-70 pb-100">
    <div class="container">
        <div class="row">
            <div>
                <div class="blog-details-desc ">
                    <ng-container *ngIf="event?.eventImages.length>0">
                        <swiper-container style=" --swiper-navigation-size: 25px; margin-bottom: 10px; width:70%;"
                            appSwiper #swiper thumbs-swiper=".slider-preview"navigation="true" autoplay="true" navigation="true" >
                            <swiper-slide *ngFor="let item of event?.eventImages">
                                <div class="ratio ratio-4x3"
                                    [ngStyle]="{background: 'url(' + photoUrl+item.imagePath +') no-repeat center center / contain'}">
                                </div>
                            </swiper-slide>
                        </swiper-container>
                        <swiper-container class="slider-preview"
                            appSwiper #swiperThumbs  space-between="10" slides-per-view="7" navigation="true"
                            *ngIf="event?.eventImages.length > 1" navigation="true">
                            <swiper-slide *ngFor="let item of event?.eventImages">
                                <div class="ratio ratio-4x3" role="button"
                                    [ngStyle]="{background: 'url(' + photoUrl+item.imagePath +') center / cover'}">
                                </div>
                            </swiper-slide>
                        </swiper-container>
                    </ng-container>
                    <div class="entry-meta mb-30 mt-30">
                        <ul>
                            <li>
                                <i class='bx bx-group'></i>
                                <span>{{'NUMBER_OF_VIEWS'|translate}}</span>
                                <a>{{event?.viewCount? event?.viewCount :'NOT_VIEW_YET'|translate}}</a>
                            </li>
                            <li>
                                <i class='bx bx-calendar'></i>
                                <span>{{'LAST_UPDATE'|translate}}</span>
                                <a>{{ event?.modifiedDate ? (event?.modifiedDate|date:'dd/MM/yyyy - hh:mm') :
                                    (event?.creationDate
                                    |date:'dd/MM/yyyy - hh:mm')}}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="container ">
                        <div class="row">
                            <div class="col-md-8">
                                <div class="article-content">
                                    <div id="dynamic-value"></div>

                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="courses-sidebar-information">
                                    <ul>
                                        <li *ngIf="event.longitude && event.latitude">
                                            <span><i class='bx bx-map'></i> {{'ADDRESS'|translate}}</span>
                                            <a href="http://www.google.com/maps/place/{{event.longitude}},{{event.latitude}}"
                                                target="_blank">{{event.address}}</a>

                                        </li>
                                        <li *ngIf="!event.longitude && !event.latitude">
                                            <span><i class='bx bx-map'></i> {{'ADDRESS'|translate}}</span>
                                            {{event.address}}

                                        </li>
                                        <li *ngIf="event?.startDateTime">
                                            <span><i class='bx bx-calendar'></i> {{'START_DATE'|translate}}</span>
                                            {{event.startDateTime| date:'dd/MM/yyyy - hh:mm'}}
                                        </li>
                                        <li *ngIf="event?.endDateTime">
                                            <span><i class='bx bx-calendar'></i> {{'END_DATE'|translate}}</span>
                                            {{event.endDateTime|
                                            date:'dd/MM/yyyy - hh:mm'}}
                                        </li>
                                        <li *ngIf="event?.endAppealDate">
                                            <span><i class='bx bx-calendar-exclamation'></i>
                                                {{'END_APPEAL_DATE'|translate}}</span>
                                            {{event?.endAppealDate| date:'dd/MM/yyyy - hh:mm'}}
                                        </li>
                                        <li>
                                            <span><i class='bx bi-people'></i> {{'QUOTA'|translate}}</span>
                                            {{event.quota}} {{'FOR_PEOPLE'|translate}}
                                        </li>

                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>







                </div>
            </div>

        </div>




    </div>
</div>