
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Content } from 'src/app/models/contentPage';



@Injectable({
    providedIn: 'root'
})
export class ContentService {
    path = environment.apiUrl;
    languageCode: string = '';
    newsId = 10;
    sliderId = 9;
    salmonId = 8;
    memoriesId = 28;

    constructor(private http: HttpClient) {
        this.languageCode = localStorage.getItem('language');
    }


    getAllContentResource(contentTypeId?, search?, creationDate?, skip?, pageSize?): Observable<any> {
        const url = this.path + `GetAllContentResource?LanguageCode=${this.languageCode}&ContentTypeId=${contentTypeId}&SearchQuery=${search}&CreationDate=${creationDate}&Skip=${skip}&PageSize=${pageSize}`;
        return this.http.get<any>(url);
    }

    getAllNews(): Observable<Content> {
        const url = this.path + `GetAllContentResource?LanguageCode=${this.languageCode}&ContentTypeId=${this.newsId}`;
        return this.http.get<Content>(url);
    }

    getAllSlider(): Observable<Content> {
        const url = this.path + `GetAllContentResource?LanguageCode=tr&ContentTypeId=${this.sliderId}`;
        return this.http.get<Content>(url);
    }

    getAllMemories(): Observable<Content> {
        const url = this.path + `GetAllContentResource?LanguageCode=${this.languageCode}&ContentTypeId=${this.memoriesId}`;
        return this.http.get<Content>(url);
    }

    getContentById(id): Observable<Content> {
        const url = this.path + `GetContentById?Id=${id}`;
        return this.http.get<Content>(url);
    }

    getAllTabContent(): Observable<Content> {
        const url = this.path + `GetAllTabContent?LanguageCode=${this.languageCode}`;
        return this.http.get<Content>(url);
    }




}