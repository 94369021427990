
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Page } from 'src/app/models/page';



@Injectable({
    providedIn: 'root'
})
export class PageService {
    path = environment.apiUrl;

    constructor(private http: HttpClient) { }

    getContentPage(id): Observable<Page> {
        const url = this.path + `GetContentPageById?Id=${id}`;
        return this.http.get<Page>(url);

    }
}