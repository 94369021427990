import { Component, OnInit } from '@angular/core';
import { BeforeSlideDetail } from 'lightgallery/lg-events';
import lgVideo from 'lightgallery/plugins/video';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { environment } from 'src/environments/environment';
import { AppSettingService } from '../../../services/appsetting.service';
import { ContentService } from '../../../services/content.service';
import { EducationService } from '../../../services/education.service';
import { EventService } from '../../../services/event.service';
import { PublicationService } from '../../../services/publication.service';
import { SermonService } from '../../../services/sermon.service';
import { SmallItemService } from '../../../services/smallItem.service';

@Component({
  selector: 'app-home-page-eight',
  templateUrl: './home-page-eight.component.html',
  styleUrls: ['./home-page-eight.component.scss']
})
export class HomePageEightComponent implements OnInit {
  photoUrl: string = environment.serverUrl
  isVisible: boolean = true;
  breakpoint: number = 991;
  smallAnnouncement: any;
  isSmallAnnouncement: boolean = false;
  news: any[] = [];
  tabs: any[] = [];
  specialSermon: any;
  memories: any[] = [];
  eventStatu: string;
  educationStatu: string;
  skip: number = 0;
  pageSize: number = 12;
  totalCount: number = 0;
  appSettings: any;
  constructor(private contentService: ContentService, private smallItemService: SmallItemService, private sermonService: SermonService) { }


  dropdownOpen: boolean = false;
  public innerWidth: any;

  ngOnInit(): void {
    this.getSmallAnnouncement();
    this.getAllTabContent();
    this.getSpecialSermon();

  }




  getSmallAnnouncement() {
    this.smallItemService.getAllSmallAnnouncement().subscribe((data: any) => {
      if (data.data.some(announcement => announcement.isActive === true)) {
        this.isSmallAnnouncement = true;
      }
      this.smallAnnouncement = data.data.filter(data => data.isActive === true).sort((a, b) => a.order - b.order);

    })
  }




  getAllTabContent() {
    const lang = localStorage.getItem('language');
    this.contentService.getAllTabContent().subscribe((data: any) => {
      this.tabs = data.data.filter(tab => tab.isActive === true && tab.isDeleted === false && tab.languageCode == lang).sort((a, b) => a.order - b.order);
      this.tabs.forEach(tab => {
        tab.contents = tab.contents.filter(content => content.isActive && !content.isDeleted).sort((a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime());
      });

      
    })
  }


  getSpecialSermon() {
    this.sermonService.getSpecialContent().subscribe((data: any) => {
      this.specialSermon = data.data;
    })
  }



  isIconOpen = false;

  toggleIcons() {
    this.isIconOpen = !this.isIconOpen;
  }

  currentTab = 'tab1';
  switchTab(event: MouseEvent, tab: string) {
    event.preventDefault();
    this.currentTab = tab;
  }

  //TAB DUYURULAR

  settings = {
    counter: false,
    plugins: [lgVideo]
  };

  onBeforeSlide = (detail: BeforeSlideDetail): void => {
    const { index, prevIndex } = detail;
  };

  classApplied2 = false;
  toggleClass2() {
    this.classApplied2 = !this.classApplied2;
  }

  blogSlides: OwlOptions = {
    loop: false,
    nav: true,
    dots: true,
    autoplayHoverPause: true,
    autoplay: false,
    margin: 10,
    navText: [
      "<i class='bx bx-left-arrow-alt'></i>",
      "<i class='bx bx-right-arrow-alt'></i>"
    ],
    responsive: {
      0: {
        items: 1
      },
      500: {
        items: 2
      },
      768: {
        items: 3
      },
      1200: {
        items: 4
      }
    }
  }


  carouselEventsSlide: OwlOptions = {
    loop: false,
    nav: true,
    dots: true,
    autoplayHoverPause: true,
    autoplay: false,
    margin: 10,
    navText: [
      "<i class='bx bx-left-arrow-alt'></i>",
      "<i class='bx bx-right-arrow-alt'></i>"
    ],
    responsive: {
      0: {
        items: 1
      },
      500: {
        items: 2
      },
      768: {
        items: 3
      },
      1200: {
        items: 5
      }
    }
  }

  carouselPublishSlide: OwlOptions = {
    loop: false,
    nav: true,
    dots: true,
    autoplayHoverPause: true,
    autoplay: false,
    margin: 10,
    navText: [
      "<i class='bx bx-left-arrow-alt'></i>",
      "<i class='bx bx-right-arrow-alt'></i>"
    ],
    responsive: {
      0: {
        items: 1
      },
      350: {
        items: 2
      },
      500: {
        items: 3
      },
      600: {
        items: 4
      },
      768: {
        items: 5
      },
      1200: {
        items: 7
      }
    }
  }


  carouselSmallAnnouncementSlide: OwlOptions = {
    loop: false,
    nav: false,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    items: 1,
    navText: [
      "<i class='bx bx-left-arrow-alt'></i>",
      "<i class='bx bx-right-arrow-alt'></i>"
    ],


  }



  selectedItem = null;

  toggleAccordionItem(list) {
    list.open = !list.open;
    if (this.selectedItem && this.selectedItem !== list) {
      this.selectedItem.open = false;
    }
    this.selectedItem = list;
  }



  detectHeader() {
    this.innerWidth = window.innerWidth;
    this.isVisible = this.innerWidth >= this.breakpoint;
  }

  dropdownMenu() {
    this.dropdownOpen = !this.dropdownOpen;

  }

}


