
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Sermon } from 'src/app/models/sermon';
import { environment } from 'src/environments/environment';



@Injectable({
    providedIn: 'root'
})
export class SermonService {
    path = environment.apiUrl

    constructor(private http: HttpClient) {}

    getSpecialContent(): Observable<Sermon> {
        const url = this.path + `GetSpecialContent`;
        return this.http.get<Sermon>(url)

    }

}