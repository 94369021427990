<div *ngIf="isLoading" class="loader-wrapper">
        <div class="loader"></div>
        <div class="loader-section section-left"></div>
        <div class="loader-section section-right"></div>
                
</div>

<header class="header-area header-color-white">
        <!-- <div class="navbar-gap"> </div> -->
        <header class="header-one sticky-header" [ngClass]="[windowScrolled ? 'sticky-on':'']">
                <div class="header-topbar d-none d-sm-block">
                        <div class="container-fluid container-1470">
                                <div class="row align-items-center justify-content-between">
                                        <div class="col-sm-auto col-12">
                                                <ul class="contact-info text-center"
                                                        style="margin-bottom:0rem !important;   ">
                                                        <li *ngIf="email"><a href="mailto:{{email}}"><i
                                                                                class="fa-sharp fa-solid fa-envelope fa-lg"></i>
                                                                        {{email}}</a></li>
                                                        <li *ngIf="funeralEmail"><a href="mailto:{{funeralEmail}}"><i
                                                                                class="fa-sharp fa-solid fa-envelope fa-lg"></i>
                                                                        {{funeralEmail}}</a></li>
                                                        <li *ngIf="telephone"><a href="tel:{{telephone}}"><i
                                                                                class="bx bx-phone-call call fa-lg"></i>{{telephone}}</a>
                                                        </li>
                                                </ul>
                                        </div>
                                        <div class="col-sm-auto col-12" style="display: flex;">
                                                <div class="social-icon text-center ">
                                                        <ul
                                                                style="margin-bottom:0rem !important ;  margin-right: 15px;">
                                                                <li *ngIf="facebook"><a href="{{facebook}}"><i
                                                                                        class="fa-brands fa-square-facebook fa-xl"></i></a>
                                                                </li>
                                                                <li *ngIf="instagram"><a href="{{instagram}}"><i
                                                                                        class="fa-brands fa-instagram fa-xl"></i></a>
                                                                </li>
                                                                <li *ngIf="twitter"><a href="{{twitter}}"><i class="fa-brands fa-x-twitter fa-xl"></i></a>
                                                                </li>
                                                                <li *ngIf="youtube" > <a href="{{youtube}}"><i
                                                                                        class="fab fa-youtube fa-xl"></i></a>
                                                                </li>

                                                        </ul>
                                                </div>
                                                <div class="others-option">
                                                        <div class="dropdown language-switcher d-inline-block"
                                                                [class.active]="classApplied2">
                                                                <button class="dropdown-toggle" type="button"
                                                                        (click)="toggleClass2()">
                                                                        <img [src]="photoUrl+language?.icon"
                                                                                class="shadow" alt="image">
                                                                        <span>{{ language?.name }} <i
                                                                                        class='bx bx-chevron-down'></i></span>
                                                                </button>
                                                                <div class="dropdown-menu">
                                                                        <a href="#" *ngFor="let language of languages"
                                                                                (click)="setNewLanguageRefresh(language?.langcode)"
                                                                                class="dropdown-item d-flex align-items-center">
                                                                                <img [src]="photoUrl+language?.icon
                                                                                " class="shadow-sm" alt="flag">
                                                                                <span>{{ language?.name }}</span>
                                                                        </a>

                                                                </div>
                                                        </div>

                                                </div>
                                        </div>


                                </div>
                        </div>
                </div>

                <div class="header-navigation" [ngClass]="[visible ? '' : 'breakpoint-on']"
                        (window:resize)="detectHeader()">
                        <div class="container-fluid d-flex align-items-center justify-content-between container-1470 ">
                                <span class="header-left">

                                        <a routerLink="/">
                                                <img src="assets/img/DitibLogoBuyuk.png" alt="logo"
                                                        class="logo">
                                        </a>

                                </span>
                                <div class="header-right d-flex align-items-center justify-content-end">
                                        <div class="site-nav-menu" [ngClass]="navMethod ? 'menu-opened':''">
                                                <ul class="primary-menu" style="display: inline-block !important; margin-bottom: 1rem !important;
                                                margin-top:1rem !important;" *ngFor="let item of topmenus">
                                                        <li>
                                                                <a href="javascript:void(0)"
                                                                        [href]="item?.link ? item?.link : (item?.frontPageId ? item.slug+ '/'+ item.frontPageId : (item?.slug!='' ? item?.slug : '') )"
                                                                        target="{{ item?.link ? '_blank' : '' }}">
                                                                        {{ item.name }}
                                                                </a>
                                                                

                                                                <span class="dd-trigger"
                                                                        *ngIf="item?.inverseParent?.length > 0"
                                                                        (click)="trigger(item)">
                                                                        <i class="fal"
                                                                                [ngClass]="item.open ? 'fa-solid fa-caret-up' : 'fa-solid fa-caret-down'"></i>
                                                                </span>

                                                                <ul class="submenu"
                                                                        *ngIf="item?.inverseParent?.length>0"
                                                                        [ngClass]="item.open ? 'd-block' : ''">

                                                                        <li *ngFor="let submenu of item?.inverseParent">
                                                                                <a href="javascript:void(0)"
                                                                                        [href]="submenu?.link ? submenu?.link : (submenu?.frontPageId ? submenu.slug+ '/'+ submenu.frontPageId : (submenu?.slug ? submenu?.slug : '') )"
                                                                                        [target]="submenu?.link ? '_blank' : '' ">
                                                                                        {{ submenu.name }}
                                                                                </a>

                                                                        </li>
                                                                </ul>
                                                                
                                                                
                                                        </li>
                                                        

                                                </ul>
                                                <ul>
                                                       
                                                                <div class="others-option sidebar">
                                                                        <div class="dropdown language-switcher d-inline-block"
                                                                                [class.active]="classApplied2">
                                                                                <button class="dropdown-toggle" type="button"
                                                                                        (click)="toggleClass2()">
                                                                                        <img [src]="photoUrl+language?.icon"
                                                                                                class="shadow" alt="image">
                                                                                        <span>{{ language?.name }} <i
                                                                                                        class='bx bx-chevron-down'></i></span>
                                                                                </button>
                                                                                <div class="dropdown-menu">
                                                                                        <a href="#" *ngFor="let language of languages"
                                                                                                (click)="setNewLanguageRefresh(language?.langcode)"
                                                                                                class="dropdown-item d-flex align-items-center">
                                                                                                <img [src]="photoUrl+language?.icon
                                                                                                " class="shadow-sm" alt="flag">
                                                                                                <span>{{ language?.name }}</span>
                                                                                        </a>
                
                                                                                </div>
                                                                        </div>
                
                                                                </div>
        


                                                       
                                                       

                                                </ul>
                                                
                                                <a href="javascript:void(0)" class="nav-close"
                                                        (click)="toggleNav()"></a>
                                        </div>
                                        <div class="header-extra d-flex align-items-center">
                                                <div class="offcanvas-widget d-lg-block d-none">

                                                </div>
                                                <div class="nav-toggler" [ngClass]="navMethod ? 'menu-opened':''"
                                                        (click)="toggleNav()">
                                                        <span></span><span></span><span></span>
                                                </div>
                                        </div>
                                </div>
                        </div>
                </div>
        </header>
        <!-- 
        <div class="top-header top-header-style-four">
                <div class="container">
                        <div class="row align-items-center " style="flex:center">
                                <div class="col-lg-4 col-md-4 prayer-time" style="display: flex;">
                                        <ul class="top-header-contact-info">
                                                <li><a>Namaz Vakitleri</a></li>
                                        </ul>
                                        <a>

                                                <div class="topbar-ordering">
                                                        <select class="form-select prayer-city"
                                                                (change)="onCountrySelected($event)">
                                                                <option *ngFor="let item of country"
                                                                        [value]="item.langcode">
                                                                        {{
                                                                        item.name }}</option>
                                                        </select>
                                                </div>

                                        </a>
                                        <a>

                                                <div class="topbar-ordering">
                                                        <select class="form-select prayer-city"
                                                                (change)="onCitySelected($event)">
                                                                <option *ngFor="let item of cities" [value]="item.id">
                                                                        {{
                                                                        item.name }}</option>
                                                        </select>
                                                </div>

                                        </a>
                                </div>
                                <div class=" time-table col-lg-2 col-md-2">
                                        <div class="item col-lg-2 col-md-2">
                                                <div class="hour">
                                                        <ul>
                                                                <li class="time">Sabah</li>
                                                                <li class="time_text">12.15</li>
                                                        </ul>
                                                </div>
                                        </div>
                                        <div class="item col-lg-2 col-md-2">
                                                <div class="hour">
                                                        <ul>
                                                                <li class="time">Öğlen</li>
                                                                <li class="time_text">12.15</li>
                                                        </ul>
                                                </div>
                                        </div>
                                        <div class="item col-lg-2 col-md-2">
                                                <div class="hour">
                                                        <ul>
                                                                <li class="time">İkindi</li>
                                                                <li class="time_text">12.15</li>
                                                        </ul>
                                                </div>
                                        </div>
                                        <div class="item col-lg-2 col-md-2">
                                                <div class="hour">
                                                        <ul>
                                                                <li class="time">Akşam</li>
                                                                <li class="time_text">12.15</li>
                                                        </ul>
                                                </div>
                                        </div>
                                        <div class="item col-lg-2 col-md-2">
                                                <div class="hour">
                                                        <ul>
                                                                <li class="time">Yatsı</li>
                                                                <li class="time_text">12.15</li>
                                                        </ul>
                                                </div>
                                        </div>
                                </div>

                        </div>
                </div>
        </div> -->

</header>

<!-- <div class="search-overlay" [class.active]="classApplied3">
        <div class="d-table">
                <div class="d-table-cell">
                        <div class="search-overlay-layer"></div>
                        <div class="search-overlay-layer"></div>
                        <div class="search-overlay-layer"></div>
                        <div class="search-overlay-close" (click)="toggleClass3()">
                                <span class="search-overlay-close-line"></span>
                                <span class="search-overlay-close-line"></span>
                        </div>
                        <div class="search-overlay-form">
                                <form>
                                        <input type="text" class="input-search" placeholder="Search here...">
                                        <button title="submit" type="submit"><i class='bx bx-search-alt'></i></button>
                                </form>
                        </div>
                </div>
        </div>
</div> -->