import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentService } from 'src/app/services/content.service';
import { environment } from 'src/environments/environment';
import { SwiperContainer } from 'swiper/element';
import { SwiperOptions } from 'swiper/types';
@Component({
  selector: 'app-news-info-page',
  templateUrl: './news-info-page.component.html',
  styleUrls: ['./news-info-page.component.scss'],
  styles: []
})
export class NewsInfoPageComponent {
  photoUrl: string = environment.serverUrl
  news: any;
  lastData: any[] = [];
  public href = window.location.href;
  constructor(private activeRoute: ActivatedRoute, private contentService: ContentService, private router: Router) { }

  ngOnInit() {
    this.activeRoute.data.subscribe(
      (data: { news: any }) => {
        if (data.news) {
          this.news = data?.news?.data;
          const editorContainer = document.getElementById("dynamic-value");
          const shadow = editorContainer.attachShadow({ mode: "closed" });

          const content = document.createElement("div");
          content.innerHTML = this.news.description;

          shadow.appendChild(content);
          this.getAll();

        }
      });

  }

  getAll() {
    this.contentService.getAllContentResource(this.news?.contentTypeId, '', '1.01.0001+00:00:00,', 0, 10).subscribe((data: any) => {
      this.lastData = data?.data.filter(lastData => lastData.isActive === true && lastData.id !== this.news?.id).sort((a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime()).slice(0, 4);
    })
  }


  navigate(id) {
    this.router.navigate(['/haber-detay', id]).then(() => {
      window.location.reload();
    });

  }


}
