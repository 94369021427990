<div class="left-box">
    <!-- <a  (click)="userPanel()" target="_blank"> -->
    <a (click)="navigate()">
        <p>
            {{'ONLINE_DONATION' | translate}}
        </p>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                d="M15.6352 12.0011C15.0347 12 14.5089 11.6689 14.2166 11.1731L14.2125 11.1655L12.0025 7.35763L9.79144 11.1643C9.49434 11.6693 8.96735 12.0016 8.36552 12.0035H8.36527C8.36487 12.0035 8.36437 12.0035 8.36391 12.0035C8.20312 12.0035 8.04787 11.9786 7.90167 11.9322L7.91288 11.9353L3.19441 10.5367V16.8938C3.19441 16.8944 3.19441 16.8951 3.19441 16.8958C3.19441 17.4271 3.54452 17.8734 4.0181 17.9992L4.02567 18.0009L11.4563 19.9331C11.6162 19.9756 11.7999 20 11.989 20C12.1781 20 12.3617 19.9756 12.5371 19.9296L12.5217 19.9331L19.959 18.0009C20.4394 17.871 20.7886 17.425 20.7903 16.894V10.5367L16.0876 11.933C15.9526 11.9762 15.7974 12.0011 15.6366 12.0011C15.6361 12.0011 15.6357 12.0011 15.6352 12.0011L15.6352 12.0011ZM22.9425 7.99345L21.1725 4.32187C21.0771 4.12938 20.8871 4 20.6681 4C20.6434 4 20.619 4.00164 20.5951 4.00485L20.598 4.00455L12.0025 5.1434L15.1546 10.5755C15.2555 10.7461 15.4341 10.8581 15.6379 10.8581C15.6921 10.8581 15.7445 10.8502 15.7942 10.8354L15.7901 10.8364L22.5919 8.81847C22.8296 8.74525 23 8.52032 23 8.25405C23 8.16002 22.9787 8.07116 22.941 7.99237L22.9425 7.99579V7.99347L22.9425 7.99345ZM2.82794 4.32189L1.05797 7.99347C1.02143 8.06908 1.00001 8.15828 1.00001 8.25264C1.00001 8.51779 1.16902 8.74192 1.40116 8.81509L1.40517 8.81617L8.20687 10.8341C8.25253 10.8479 8.30494 10.8558 8.35913 10.8558C8.56294 10.8558 8.74155 10.7438 8.84105 10.5757L8.84245 10.5732L11.9979 5.14462L3.4024 4.00577C3.38391 4.00354 3.36248 4.00227 3.34076 4.00227C3.12216 4.00227 2.93252 4.13118 2.8384 4.3198L2.83692 4.32309L2.82792 4.32187L2.82794 4.32189Z"
                fill="white" />
        </svg>
    </a>
</div>

<!-- <div class="user-panel" *ngIf="active!=false">
    <div class="right-panel">
        <div class="user" style="gap: 40px; text-align: end;">
            <button  class="user-menu-button">
               <svg (click)="userPanel()" xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19"
                  fill="none">
                  <g opacity="0.5">
                     <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M18.6059 18.6059C18.0805 19.1314 17.2286 19.1314 16.7032 18.6059L0.394071 2.2968C-0.131354 1.77138 -0.131354 0.919494 0.394071 0.394069C0.919496 -0.131356 1.77138 -0.131357 2.2968 0.394069L18.6059 16.7032C19.1314 17.2286 19.1314 18.0805 18.6059 18.6059Z"
                        fill="#092126" />
                     <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M18.6059 0.39407C19.1314 0.919495 19.1314 1.77138 18.6059 2.2968L2.2968 18.6059C1.77138 19.1314 0.919494 19.1314 0.394069 18.6059C-0.131356 18.0805 -0.131357 17.2286 0.394069 16.7032L16.7032 0.39407C17.2286 -0.131355 18.0805 -0.131355 18.6059 0.39407Z"
                        fill="#092126" />
                  </g>
               </svg>
            </button>
         </div>

         <h4 style="font-size: 27px;padding:0;">{{baslik}}</h4>

         <p>{{yazi}}</p>

            <div style="text-align: start;padding: 5px;">
            <div style="margin-bottom: 10px;border-bottom: groove red;"><a target="_blank" class="online-a" href="{{link1}}">&raquo; GENEL BAĞIŞ</a><br></div>
            <div style="margin-bottom: 10px;border-bottom: groove red;"><a target="_blank" class="online-a" href="{{link2}}">&raquo; SU KUYUSU</a><br></div>
            <div style="margin-bottom: 10px;border-bottom: groove red;"><a target="_blank" class="online-a" href="{{link3}}">&raquo; VEKALETEN KURBAN BAĞIŞI</a><br></div>
            <div style="margin-bottom: 10px;border-bottom: groove red;"><a target="_blank" class="online-a" href="{{link4}}">&raquo; ZEKAT</a><br></div>
            <div style="margin-bottom: 10px;border-bottom: groove red;"><a target="_blank" class="online-a" href="{{link5}}">&raquo; FİTRE</a><br></div>
            <div style="margin-bottom: 10px;border-bottom: groove red;"><a target="_blank" class="online-a" href="{{link6}}">&raquo; FİDYE</a><br></div>
        </div>
    </div>
</div> -->