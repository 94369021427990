<app-banner [data]="{content: 'PUBLICATION' | translate, title: 'PUBLICATION' | translate }"></app-banner>

<div class="product-details-area pt-70 pb-70">
    <div class="container" style="    box-shadow: 15px 15px 30px #bebebe,
    -15px -15px 30px #ffffff;">
        <div class="row align-items-center">
            <div class="col-lg-4 col-md-12">
                <div class="products-details-image">
                    <owl-carousel-o [options]="detailsImageSlides">
                        <ng-template carouselSlide *ngFor="let item of publish?.publicationPictures">
                            <img *ngIf="publish?.publicationPictures?.length > 0" [src]="photoUrl+item?.imagePath"
                                alt="image">
                            <img *ngIf="publish?.publicationPictures?.length == 0"
                                src="assets/img/DitibLogoBuyuk.png" alt="image">
                        </ng-template>
                    </owl-carousel-o>
                </div>
            </div>
            <div class="col-lg-8 col-md-12 mtb-30">
                <div class="product-details-desc container" id="publication">
                    <h3>{{publish?.publicationName}} </h3>
                    <h3 class="writer-name">{{'WRITER_PUBLICATION'|translate}}{{publish?.writerName}}</h3>
                    <!-- <div class="price">
                        <span class="new-price">$54.00</span>
                    </div> -->

                    <p [innerHTML]="publish.description" ></p>
                    <!-- <div class="product-meta">
                        <span>SKU: <span class="sku">10</span></span>
                        <span>Availability: <span class="in-stock">In Stock</span></span>
                        <span>Category: <a routerLink="/">Business</a></span>
                        <span>Tag: <a routerLink="/">Book</a></span>
                    </div> -->

                    <div class="custom-payment-options ">
                        <a [href]="photoUrl+publish?.folderPath" class="router-button" target="_blank">
                            <button class="view-all-contents-button " *ngIf="publish?.folderPath">
                                {{'PREVIEW'|translate}}
                            </button>
                        </a>
                        <a [href]="photoUrl+publish?.folderPath" class="router-button" download>
                            <button class="view-all-contents-button blue" *ngIf="publish?.folderPath">

                                {{'DOWNLOAD'|translate}}

                            </button>
                        </a>
                        <!-- <button class="view-all-contents-button blue">
                            <a href="">
                                {{'BUY'|translate}}
                            </a>
                        </button> -->
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="related-products pb-70" *ngIf="publications?.length>0">
        <div class="container">
            <div class="container publish">
                <h4>{{'SIMILAR_PUBLICATIONS'|translate}}</h4>
                <div class="blog-slides">
                    <owl-carousel-o [options]="carouselPublishSlide">
                        <ng-template carouselSlide *ngFor="let item of publications">
                            
                            <app-publish-card [item]="item"></app-publish-card>

                        </ng-template>

                    </owl-carousel-o>
                </div>
            </div>
        </div>
    </div>
</div>