import { Component, OnInit } from '@angular/core';
import { Router, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { TranslationService } from './services/translation.service';
import { TranslateService } from '@ngx-translate/core';
declare let $: any;

import { register } from 'swiper/element/bundle';
register();
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent {
    location: any;
    routerSubscription: any;

    constructor(private router: Router,
        public translate: TranslateService,
        private translationService: TranslationService) {
        translate.addLangs(['tr', 'en','fr']);
        translate.setDefaultLang('tr');
        this.setLanguage();
    }

    setLanguage() {
        const currentLang = this.translationService.getSelectedLanguage();
        if (currentLang) {
            this.translationService.setLanguage(currentLang)
                .subscribe(() => { });
        }
        else {
            const browserLang = this.translate.getBrowserLang();
            const lang = browserLang.match(/tr|en|fr/) ? browserLang : 'tr';

            this.translationService.setLanguage(lang).subscribe(() => { });
        }
    }

    ngOnInit() {
        this.recallJsFuntions();
    }

    recallJsFuntions() {
        this.routerSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
            .subscribe(event => {
                this.location = this.router.url;
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
    }
}