import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve
} from '@angular/router';
import { Observable } from 'rxjs';
import { EducationService } from '../../../services/education.service';
import { Education } from 'src/app/models/education';

@Injectable()
export class EducationResolver implements Resolve<any> {
    constructor(private educationService: EducationService) { }
    resolve(
        route: ActivatedRouteSnapshot
    ): Observable<Education> {
        const id = route.paramMap.get('id');
        return this.educationService.getEducationById(id) as Observable<Education>;
    }
}